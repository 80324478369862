<template>
  <div>
    <NavBar
    style="max-height: 50px;margin-bottom: 10px;"
    :username="currentUserName"
    :pageName="pageName"
    ></NavBar>
    <v-app>
      <div style="background-color: #f5faff; height: 93vh !important;">
        <v-row cols="12">
        <v-col cols="2">
            <SideBar style="max-height: 50px;" :pageName="pageName"></SideBar>
        </v-col>
          <v-col cols="10" style="overflow:scroll; height:90vh">
            <div style="margin: 42px 20px 20px 50px">
              <div
              style="display:flex;
                     flex-direction:row;
                     flex-wrap:wrap;
                     justify-content:space-between;
                     vertical-align:middle;
                     ">

              <!-- <v-row style="margin-left: 5px"> -->
                <div style="width:30%;margin-top:3vh" align="left">
                <span style="font-weight: 800;padding-left: 5px; font-size: 12pt;
                text-transform: uppercase;   letter-spacing: 1px;">
                  <h3>Doctors Information</h3>
                </span>
                </div>
                <!-- <span style="margin-top: -3%; margin-left: 80%"> -->
                 <div style="display:flex;flex-direction: row;justify-content:center; width:50%;align-items: end;margin-right: 10vh">
                  <div style="display:flex;flex-direction: row;justify-content:space-between;align-items: end;">
                    <v-select
                    v-model="search_type"
                    :items="items"
                    :menu-props="{ top: true, offsetY: true }"
                    placeholder="Search With"
                    solo
                    hide-details
                    height="30"
                    dense
                    @change="phone_number='',doctor_name='',doctor_sama_id='',specialization='',email=''"
                    style="width: 20%; margin-right: 2px;"
                    ></v-select>
                  </div>
                  <div style="display:flex;flex-direction: row;justify-content:space-between;align-items: end;margin-left: 2vh;">
                        <v-text-field
                        v-if="search_type == 'Phone Number'"
                        v-model="phone_number"
                        placeholder="Type here"
                        label="Enter Phone number"
                        :rules="mobileNumberRules"
                        dense
                        solo
                        hide-details
                        @change="doctor_name='',doctor_sama_id='',specialization='',email=''"
                        style="width: 20%;margin-right: 2px;"
                        ><template v-slot:append>
                        <v-btn @click="filterdoctors(search_type)" icon>
                            <img
                            style="width: 25px; height: 25px"
                            src="https://s3iconimages.mymedicine.com.mm/search.svg"
                            />
                        </v-btn>
                        </template>
                        </v-text-field>
                  </div>
                  <div style="display:flex;flex-direction: row;justify-content:space-between;align-items: end;margin-left: 2vh;">
                        <v-text-field
                        v-if="search_type == 'Doctor Name'"
                        v-model="doctor_name"
                        placeholder="Type here"
                        label="Enter Doctor Name"
                        dense
                        solo
                        hide-details
                        @change="phone_number='',doctor_sama_id='',specialization='',email=''"
                        style="width: 20%;margin-right: 2px;"
                        ><template v-slot:append>
                        <v-btn @click="filterdoctors(search_type)" icon>
                            <img
                            style="width: 25px; height: 25px"
                            src="https://s3iconimages.mymedicine.com.mm/search.svg"
                            />
                        </v-btn>
                        </template>
                        </v-text-field>
                  </div>
                  <div style="display:flex;flex-direction: row;justify-content:space-between;align-items: end;margin-left: 2vh;">
                        <v-text-field
                        v-if="search_type == 'Doctor SAMA ID'"
                        v-model="doctor_sama_id"
                        placeholder="Type here"
                        label="Enter Doctor SAMA ID"
                        dense
                        solo
                        hide-details
                        @change="phone_number='',doctor_name='',specialization='',email=''"
                        style="width: 20%;margin-right: 2px;"
                        ><template v-slot:append>
                        <v-btn @click="filterdoctors(search_type)" icon>
                            <img
                            style="width: 25px; height: 25px"
                            src="https://s3iconimages.mymedicine.com.mm/search.svg"
                            />
                        </v-btn>
                        </template>
                        </v-text-field>
                  </div>
                  <div style="display:flex;flex-direction: row;justify-content:space-between;align-items: end;margin-left: 2vh;">
                        <v-select
                        v-if="search_type == 'Specialization'"
                        v-model="specialization"
                        :items = "specialist_list"
                        item-value = "_id"
                        item-text = "specialization_name"
                        return-object
                        :menu-props="{ top: true, offsetY: true }"
                        placeholder="Search With"
                        height="30"
                        dense
                        solo
                        hide-details
                        @change="filterdoctors(search_type)"

                        style="width: 20%;margin-right: 2px;"
                        >
                         <!-- @change="phone_number='',doctor_name='',doctor_sama_id='',email=''" -->
                        </v-select>
                  </div>
                  <div style="display:flex;flex-direction: row;justify-content:space-between;align-items: end;margin-left: 2vh;">
                        <v-text-field
                        v-if="search_type == 'Email'"
                        v-model="email"
                        placeholder="Type here"
                        label="Enter Email ID"
                        dense
                        solo
                        hide-details
                        @change="phone_number='',doctor_name='',doctor_sama_id='',specialization=''"
                        style="width: 20%;margin-right: 2px;"
                        ><template v-slot:append>
                        <v-btn @click="filterdoctors(search_type)" icon>
                            <img
                            style="width: 25px; height: 25px"
                            src="https://s3iconimages.mymedicine.com.mm/search.svg"
                            />
                        </v-btn>
                        </template>
                        </v-text-field>
                  </div>
                    <div style="display:flex;flex-direction: row;width: 20%">
                      <div style="border-style: solid; border-color: white; border-radius: 8px; border-width: 0.1em;">
                        <span style="font-size: 26pt; display: flex; justify-content: space-between;align-items: end;">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }" style="display: flex; justify-content: end; align-items: center;">
                              <v-btn append rounded color="primary" v-on="on" style="height: 35px; margin-top:2vh" @click="addDoctorFunction">
                                <span style="font-size: 18pt;"> + </span>
                                <span style="font-weight: 600;">  Add Doctor</span>
                              </v-btn>
                            </template>
                            <span>Add Doctor</span>
                          </v-tooltip>
                        </span>
                      </div>
                    </div>
                  </div>
                <!-- </span> -->

              <!-- </v-row> -->
              </div>
              <div v-if="!isLoading" style="overflow: scroll; overflow-x: hidden; margin-top: 2%; height:45px 0px">
                <table class="tableClass"  v-if="doctor_list.length>0">
                  <thead>
                    <tr>
                      <th class="text-left">Doctor Name</th>
                      <th class="text-left">Specialization</th>
                      <th class="text-left">Email</th>
                      <th class="text-left">Phone number</th>
                      <th class="text-left">Doctor SAMA ID</th>
                      <th class="text-left">Availability Status</th>
                      <th class="text-left">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="doctor in doctor_list" :key="doctor.doctor_phonenumber">
                      <td   align="left">
                        {{ doctor.doctor_name }}
                      </td>
                      <td   align="left">
                        {{ doctor.specialist[0].specialization_name ? doctor.specialist[0].specialization_name : doctor.specialist[0].name }}
                      </td>
                      <td align="left">
                        {{ doctor.doctor_email }}
                      </td>
                      <td align="left">
                        {{ doctor.doctor_phonenumber }}
                      </td>
                      <td align="left">
                        {{ doctor.doctor_id }}
                      </td>
                      <td  align="left">
                        <v-switch
                            color = "primary"
                            :loading = "isEditLoading"
                            :disabled = "isDisabled"
                            dense
                            v-model = "doctor.available_status"
                            @change="changeStatus(doctor._id,doctor.available_status)"
                        ></v-switch>
                      </td>
                      <td align="left">
                        <a
                          class="btnClass"
                          depressed
                          icon
                          @click="editDoctor(doctor._id)"
                          >
                          <img
                          src="https://s3iconimages.mymedicine.com.mm/edit.svg"
                          />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else >
                  <p style="margin-top:1%">
                    No Data Found!
                  </p>
                </div>
              </div>
              <v-progress-circular
                  indeterminate
                  color="primary"
                  v-else
              ></v-progress-circular>
              <div v-if="doctor_list">
                <v-pagination
                  v-if="!hidePagination && !filterflag && !isLoading && pagination_list > 1"
                  circle
                  v-model="page"
                  :length="pagination_list"
                  :total-visible="7"
                  @input="findPage(page)"
                ></v-pagination>
                <v-pagination
                  v-if="filterflag && !isLoading && pagination_list > 1"
                  circle
                  v-model="filterpage"
                  :length="pagination_list"
                  :total-visible="7"
                  @input="findFilterPage(filterpage)"
                ></v-pagination>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import { axios_auth_instance_admin } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';

export default {
  name: "ViewAllDoctor",
  data() {
    return {
      doctor_list: "",
      isEditLoading: false,
      isDisabled: false,
      user_id: "",
      currentUser: "",
      currentUserName: "",
      pageName: "",
      isLoading : false,
      pagination_list:0,
      page:1,
      filterpage:1,
      items: ["Phone Number","Doctor Name","Doctor SAMA ID","Specialization","Email"],
      mobileNumberRules: [ (v) => !!v || 'Mobile Number is required',(v) => v > 0 || "Mobile number cannot be negative"],
      search_type: "",
      phone_number:"",
      doctor_name: "",
      doctor_sama_id: "",
      specialization: "",
      email:"",
      hidePagination: false,
      specialist_list : "",
      filterflag : false
    };
  },
  mounted() {
    this.pageName = "ViewAllDoctors";
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      var data = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        page : 1,
        limit : 10
      };
      this.isLoading = true;
      axios_auth_instance_admin.post("/adminAllDoctorsPage", data)
      .then((successResponse) => {
        this.currentUserName = successResponse.data.admin_data.admin_name;
        this.doctor_list = successResponse.data.data.docs;
        this.specialist_list = successResponse.data.data.specialization_data;
        this.doctor_list.forEach((x) => {
          x.available_status = x.available_status == 'AVAILABLE';
        });
        this.pagination_list = successResponse.data.data.pages;
        this.isLoading = false;
      })
      .catch((errorResponse) => {
        this.isLoading = false;
        handleError(errorResponse, this.$router, 'adminAllDoctorsPage', 'AdminLogin', 'token');
      });
    }
  },
  components: {
    NavBar,SideBar,
  },
  methods: {
    filterdoctors(search_type){
      this.isLoading = true;
      let params = ''
      // this.pagination_list = 0
      // this.page = 1
      // this.hidePagination = true
      // this.filterpage=1
      if(search_type == 'Phone Number'){
        if(this.phone_number!=""){
            params = {
            filterType : this.search_type,
            filterValue : this.phone_number.trim(),
            token : this.currentUser,
            typeOfUser : "ADMIN",
            page : 1,
            limit : 10
          }
        }
        else if(this.phone_number=="")
        {
          window.alert("Enter Phone Number")
        }
      }
      else if(search_type == 'Doctor Name'){
        if(this.doctor_name!=""){
            params={
            filterType : this.search_type,
            filterValue : this.doctor_name.trim(),
            token : this.currentUser,
            typeOfUser : "ADMIN",
            page : 1,
            limit : 10
          }
        }
        else if(this.doctor_name=="")
        {
          window.alert("Enter Doctor Name")
        }
      }
      else if(search_type == 'Doctor SAMA ID'){
        if(this.doctor_sama_id!="")
        {
          params={
          filterType : this.search_type,
          filterValue : this.doctor_sama_id.trim(),
          token : this.currentUser,
          typeOfUser : "ADMIN",
          page : 1,
          limit : 10
          }
        }
        else if(this.doctor_sama_id==""){
          window.alert("Enter Doctor SAMA ID")
        }
      }
      else if(search_type == 'Specialization'){
        params={
          filterType : this.search_type,
          filterValue : this.specialization._id,
          token : this.currentUser,
          typeOfUser : "ADMIN",
          page : 1,
          limit : 10
        }
      }
      else if(search_type == 'Email'){
        if(this.email!=""){
          params={
          filterType : this.search_type,
          filterValue : this.email.trim(),
          token : this.currentUser,
          typeOfUser : "ADMIN",
          page : 1,
          limit : 10
          }
        }
        else if(this.email==""){
          window.alert("Enter Email ID")
        }
      }
      // else{
      //   console.log("Check Your Filter Key")
      // }

      if(params){
                // console.log("params",params)
                axios.post(process.env.VUE_APP_BACKEND_URL + "/filterDoctors", params)
                    .then((filterDoctorsResponse)=>{
                        if(filterDoctorsResponse.status == 200)
                        {
                        // console.log("filterDoctorsResponse",filterDoctorsResponse.data.data)
                        this.doctor_list = filterDoctorsResponse.data.data.docs
                        this.doctor_list.forEach((x) => {
                         x.available_status = x.available_status == 'AVAILABLE' ? true : false;
                        });
                        this.pagination_list = filterDoctorsResponse.data.data.totalPages;
                        // console.log("paginated list",this.pagination_list)
                        this.isLoading = false;
                        this.filterflag = true;
                        }
                    })
                    .catch((filterDoctorsError)=>{
                        if(filterDoctors.response.status == 400)
                        {
                        // console.log("filterDoctorsError",filterDoctorsError)
                          window.alert("Error in Filters")
                        }
                        // else  if(filterDoctors.response.status == 403)
                        // {
                        // console.log("filterDoctorsError",filterDoctorsError.response.data)
                        // }
                    })
      }
    },
    changeStatus(doctor_id,available_status){
      this.isEditLoading = 'error';
      this.isDisabled = true;
      var updateData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        doctorId: doctor_id,
        availableStatus: available_status ? 'AVAILABLE' : 'UNAVAILABLE'
      };
      axios.post(process.env.VUE_APP_BACKEND_URL+"/updateDoctorAvailableStatus",updateData)
      .then((updateResponse) => {
        this.isEditLoading = false;
        this.isDisabled = false;
      })
      .catch((updateError)=>{
        window.alert("Error while updating status");
        this.isEditLoading = false;
        this.isDisabled = false;
      });
    },
    findPage(pageNum){
      this.isLoading = true
      var getDoctorData = {
          token: this.currentUser,
          typeOfUser: "ADMIN",
          page: pageNum,
          limit: 10,
        };
       axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/getAllDoctors",
          getDoctorData
          )
        .then((getDoctorListResponse) => {
          this.doctor_list = getDoctorListResponse.data.data.docs;
          this.doctor_list.forEach((x) => {
            x.available_status = x.available_status == 'AVAILABLE' ? true : false;
          });
          this.pagination_list = getDoctorListResponse.data.data.totalPages;
          this.isLoading = false
        })
        .catch((getDoctorListError) => {
          this.isLoading = false
          if (getDoctorListError.response.status == 401) {
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.push({
              name: "AdminLogin",
            });
          } else {
            window.alert("Something went wrong...");
          }
        });
    },
    findFilterPage(filterpage){
      this.isLoading=true;
      let params = ''
      // this.pagination_list = 0
      // this.page = 1
      // this.hidePagination = true
      if(this.search_type == 'Phone Number'){
        if(this.phone_number!=""){
            params = {
            filterType : this.search_type,
            filterValue : this.phone_number.trim(),
            token : this.currentUser,
            typeOfUser : "ADMIN",
            page : filterpage,
            limit : 10
          }
        }
        else if(this.phone_number=="")
        {
          window.alert("Enter Phone Number")
        }
      }
      else if(this.search_type == 'Doctor Name'){
        if(this.doctor_name!=""){
            params={
            filterType : this.search_type,
            filterValue : this.doctor_name.trim(),
            token : this.currentUser,
            typeOfUser : "ADMIN",
            page : filterpage,
            limit : 10
          }
        }
        else if(this.doctor_name=="")
        {
          window.alert("Enter Doctor Name")
        }
      }
      else if(this.search_type == 'Doctor SAMA ID'){
        if(this.doctor_sama_id!="")
        {
          params={
          filterType : this.search_type,
          filterValue : this.doctor_sama_id.trim(),
          token : this.currentUser,
          typeOfUser : "ADMIN",
          page : filterpage,
          limit : 10
          }
        }
        else if(this.doctor_sama_id==""){
          window.alert("Enter Doctor SAMA ID")
        }
      }
      else if(this.search_type == 'Specialization'){
        params={
          filterType : this.search_type,
          filterValue : this.specialization._id,
          token : this.currentUser,
          typeOfUser : "ADMIN",
          page : filterpage,
          limit : 10
        }
      }
      else if(this.search_type == 'Email'){
        if(this.email!=""){
          params={
          filterType : this.search_type,
          filterValue : this.email.trim(),
          token : this.currentUser,
          typeOfUser : "ADMIN",
          page : filterpage,
          limit : 10
          }
        }
        else if(this.email==""){
          window.alert("Enter Email ID")
        }
      }
      else{
        // console.log("Check Your Filter Key")
      }

      if(params){
                // console.log("params",params)
                axios.post(process.env.VUE_APP_BACKEND_URL + "/filterDoctors", params)
                    .then((filterDoctorsResponse)=>{
                        if(filterDoctorsResponse.status == 200)
                        {
                        // console.log("filterDoctorsResponse",filterDoctorsResponse.data.data)
                        this.doctor_list = filterDoctorsResponse.data.data.docs
                        this.doctor_list.forEach((x) => {
                         x.available_status = x.available_status == 'AVAILABLE' ? true : false;
                        });
                        this.pagination_list = filterDoctorsResponse.data.data.totalPages;
                        // console.log("paginated list",this.pagination_list)
                        this.isLoading = false;
                        this.filterflag = true;
                        }
                    })
                    .catch((filterDoctorsError)=>{
                        if(filterDoctors.response.status == 400)
                        {
                        // console.log("filterDoctorsError",filterDoctorsError)
                        window.alert("Error in Filters")
                        }
                        // else  if(filterDoctors.response.status == 403)
                        // {
                        // console.log("filterDoctorsError",filterDoctorsError.response.data)
                        // }
                    })
      }
    },
    addDoctorFunction() {
      this.$router.push({
        name: "CreateDoctor",
      });
    },
    gotoLoginPage() {
      var deleteData = {
        idToken: this.currentUser,
      };
      axios
      .post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken", deleteData)
      .then((deleteResponse) => {
        this.$cookies.remove("token");
      })
      .catch((error) => {
      });
      localStorage.clear();
      this.$router.push({
        name: "AdminLogin",
      });
    },
    editDoctor(doctor_id) {
      this.$router.push({
        path: "editdoctor/" + doctor_id,
      });
    },
  },
};
</script>

<style scoped>
th {
  border: 0px;
  /* padding: 10px; */
}
td {
  font-size: 8pt !important;
  font-weight: 700;
  height: 14px;
  text-align: left;
  padding-left:  5px;

}
th {
  background-color: #1467bf;
  color: white;
  border: 0px;
  font-size: 8pt;
  font-weight: 500;
  margin-left: 10%;
  position: sticky;
  top: 0;
  z-index: 0;
  height: 40px;
  padding-left: 5px;
  text-align: left;
}
tr {
  border-bottom: #d4efff 1px solid !important;
  height: 15px;
}
.tableClass {
  border-spacing: 0px;
  width: 100%;
  border-collapse: collapse;
  z-index: 0;
  background-color: white;
  /* margin-left: 10px; */
  margin-top: 21px;

}
table > tr > td:last-child {
  white-space: nowrap;
}
.btnClass {
  background-color: transparent;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#facebook{
  display: none;
}
.v-input--selection-controls{
  margin-top: 2px !important;
  /* padding-top: 10px !important; */
}
.v-input--dense>.v-input__control>.v-input__slot{
  margin-bottom: 0px !important;
}
.v-application {
    line-height: 0.4;
}

</style>